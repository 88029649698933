import React from "react";
import Layout from "../components/Layout";
import Helmet from "react-helmet";

const NotFoundPage = () => (
	<Layout title="¯\_(ツ)_/¯">
		<Helmet title="404"/>
		<h1>NOT FOUND ;(</h1>
		<p>You just hit a page that doesn&#39;t exist...</p>
	</Layout>
);

export default NotFoundPage;
